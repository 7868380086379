import React, { useEffect, useState } from "react";
import logo from "../../../Images/loyalStringLogoWide.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminLoggedIn } from "../../../../redux/action/Actions";
import "../../PagesStyles/AdminPanelLogin.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdLogin } from "react-icons/md";

export default function AdminPanelLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const loggedIn = (user) => {
    // dispatch(adminLoggedIn(user));
    dispatch(adminLoggedIn(user));
  };
  useEffect(() => {
    let showPasswordOption = document.getElementById(
      "adminPanelLoginFormShowPasswordButton"
    );
    if (showPassword === true) {
      showPasswordOption.classList.add("passwordVisible");
      showPasswordOption.classList.remove("passwordHidden");
    } else {
      showPasswordOption.classList.add("passwordHidden");
      showPasswordOption.classList.remove("passwordVisible");
    }
  }, [showPassword]);
  const handleLogin = () => {
    if (username == "admin" && password == "JayambeJewellers@123") {
      // alert(`Welcome ${username}`);
      loggedIn("1");
      navigate("/adminhome");
    } else if (username == "Branch2" && password == "JayambeJewellers@123") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("2");
    } else if (username == "Staff2" && password == "soccerlover") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("3");
    } else if (username == "Staff3" && password == "chocolate123") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("4");
    } else if (username == "Staff4" && password == "beach2020") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("5");
    } else {
      alert("Sorry Incorrect Details");
    }
  };

  return (
    <div className="adminPanelLoginOuterBox">
      <div className="adminPanelLoginInnerBox">
        <div className="adminPanelLoginBox">
          <img
            style={{ placeSelf: "flex-start", marginBottom: "30px" }}
            className="adminPanelLoginLogo"
            src={logo}
            alt="loyalStringLogo"
          />
          <form onSubmit={handleLogin}>
            {/* <div className="adminPanelLoginLogoStatementOuter">
              <div className="adminPanelLoginLogoStatement">
                <p>Enter your username and password to access admin panel.</p>
              </div>
            </div> */}
            <div className="adminPanelLoginFormLabelBox">
              <div className="adminPanelLoginFormLabelInnerBox">
                <label htmlFor="inputEmail">Username</label>
              </div>
            </div>
            <div className="adminPanelLoginFormInputBox">
              {/* <div className="adminPanelLoginFormInputInnerBox"> */}
              <input
                type="text"
                id="inputEmail"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {/* </div> */}
            </div>

            <div className="adminPanelLoginFormLabelBox">
              <div className="adminPanelLoginFormLabelInnerBox">
                <label htmlFor="inputPassword">Password</label>
              </div>
            </div>
            <div className="adminPanelLoginFormInputBox">
              <div className="adminPanelLoginFormInputInnerBox">
                <input
                  type={showPassword ? "text" : "password"}
                  id="inputPassword3"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  style={{ cursor: "pointer" }}
                  id="adminPanelLoginFormShowPasswordButton"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEyeInvisible className="adminPanelLoginFormShowPasswordIcon" />
                  ) : (
                    <AiOutlineEye className="adminPanelLoginFormShowPasswordIcon" />
                  )}
                </button>
              </div>
            </div>

            <div className="adminPanelLoginFormButtonOuterBox">
              <button
                type="submit"
                className="adminPanelLoginFormButtonInnerBox"
              >
                <MdLogin style={{ marginRight: "5px" }} />
                <div>Sign in</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
